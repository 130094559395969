import "./style.css"

const NotFoundPage = () => {

    return (
        <main className="notFoundPageContainer">
            <h2>Error 404</h2>
        </main>
    )
}

export default NotFoundPage